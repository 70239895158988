import { combineReducers } from 'redux';
import { apodReducer } from "./apod.reducer";
import { searchReducer } from "./search.reducer";

export * from './apod.actions';
export * from './apod.reducer';
export * from './apod.saga';

const store = combineReducers({
  apod: apodReducer,
  search: searchReducer
});

export default store;
