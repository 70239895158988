import {
  LOAD_LATEST_APOD, LOAD_LATEST_APOD_ERROR, LOAD_LATEST_APOD_SUCCESS
} from "./apod.actions";

let initState = {
  loading: false,
  data: {},
  error: void 0
};

export const apodReducer = (state = initState, action) => {
  switch (action.type) {
    case LOAD_LATEST_APOD:
      return { ...state, loading: true, error: '' };
    case LOAD_LATEST_APOD_SUCCESS:
      return { ...state, loading: false, data: {
        latest: action.payload
        } };
    case LOAD_LATEST_APOD_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

