import { put, takeEvery, call, fork } from 'redux-saga/effects';
import { searchMedia } from "./search.api";
import { isString } from "lodash";
import { SEARCH_QUERY, SEARCH_QUERY_ERROR, SEARCH_QUERY_SUCCESS } from "./search.actions";


// Our worker Saga: will perform the async increment task
export function* loadingSearchResultsAsync({ payload }) {
  try {
    if(isString(payload) && payload.length>2){
      const data = yield call(searchMedia, payload);
      yield put({ type: SEARCH_QUERY_SUCCESS, payload: data });
    }

  } catch (err) {
    yield put({ type: SEARCH_QUERY_ERROR, payload: err.message });
  }
}

// Our watcher Saga: spawn a new incrementAsync task on each INCREMENT_ASYNC
export function* watchSearchQueryAsync() {
  yield takeEvery(SEARCH_QUERY, loadingSearchResultsAsync);
}


export function* searchSaga() {
  yield [
    fork(watchSearchQueryAsync)
  ];
}
