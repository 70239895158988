import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Logo from "./Logo";
import easing from "../animation/easing";
import Fade from "@material-ui/core/es/Fade/Fade";


const styles = theme => ({
  root: {
    pointerEvents: "none",
    flexGrow: 1,
    overflow: "hidden",
    padding: "0",
    zIndex: 2,
    position: "absolute",
    top: "3rem",
    width: "100%"
  },
  title: {
    marginTop: "1.2rem",
    color: theme.palette.secondary[500]
  },
  logo: {
    transition: `transform 0.5s ${easing.deceleration}, scale 0.5s ${easing.deceleration}`,
    transformOrigin:'50% 50%'
  }
});
const Header = ({ title, classes, minimised }) => {

  const logoStyle = {
    transform: `scale(${minimised?'0.4':'1'})  translate(${minimised?'calc(190vw - 6rem)':'0'}, ${minimised?'7rem':'0'})`
  };
  return <Grid className={classes.root}
               container
               direction="row"
               justify="center"
               alignItems="center"
               spacing={16}>
    <Grid className={classes.logo}
          style={logoStyle}
          item>
      <Logo width="100" height="100"/>
    </Grid>
    <Grid item>
      <Fade in={!minimised}>
        <Typography className={classes.title} variant="h4">{title}</Typography>
      </Fade>
    </Grid>
  </Grid>
};

Header.propTypes = {
  minimised: PropTypes.bool,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Header);
