import { SEARCH_QUERY, SEARCH_QUERY_ERROR, SEARCH_QUERY_SUCCESS } from "./search.actions";
import {isObject, isArray} from "lodash";

let initState = {
  loading: false,
  data: {},
  resultsCount: undefined,
  error: void 0
};

export const searchReducer = (state = initState, action) => {
  switch (action.type) {
    case SEARCH_QUERY:
      return { ...state, loading: true, error: '',  data: {}
      };
    case SEARCH_QUERY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...action.payload
        },
        resultsCount: (isObject(action.payload.collection) && isArray(action.payload.collection.items))?
          action.payload.collection.items.length:0
      };
    case SEARCH_QUERY_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

