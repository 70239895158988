import { put, takeEvery, call, fork } from 'redux-saga/effects';
import { loadLatestAPODApi } from "./apod.api";
import { LOAD_LATEST_APOD, LOAD_LATEST_APOD_ERROR, LOAD_LATEST_APOD_SUCCESS } from "./apod.actions";


// Our worker Saga: will perform the async increment task
export function* loadingLatestApodAsync() {
  try {
    const data = yield call(loadLatestAPODApi);

    yield put({ type: LOAD_LATEST_APOD_SUCCESS, payload: data });
  } catch (err) {
    yield put({ type: LOAD_LATEST_APOD_ERROR, payload: err.message });
  }
}

// Our watcher Saga: spawn a new incrementAsync task on each INCREMENT_ASYNC
export function* watchLoadingApodLatestAsync() {
  yield takeEvery(LOAD_LATEST_APOD, loadingLatestApodAsync);
}


export function* apodSaga() {
  yield [
    fork(watchLoadingApodLatestAsync)
  ];
}
