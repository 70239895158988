import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
  loadApodAction
} from "../store";
import easing from "../animation/easing";

const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    position: "fixed",
    width: "100%",
    height: "100vh",
    left: 0,
    top: 0,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    zIndex: 0,
    transition: `backgroundImage 0.5s ${easing.deceleration}`,
  }
});

class Apod extends Component {
  state = {
  };

  render() {
    const { classes } = this.props;
    const { apod } = this.props;
    const { minimised } = this.props;
    const divStyle = apod.latest ? {
      backgroundImage: minimised?null:`url(${apod.latest.hdurl})`
    } : null;
    return (
      <div className={classes.root} style={divStyle}>
      </div>
    );
  }

  componentDidMount() {
    this.props.getLatestApod();
  }
}

const mapStateToProps = state => {
  return {
    apod: state.apod.data
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getLatestApod: () => {
      dispatch(loadApodAction());
    }
  };
};

Apod.propTypes = {
  classes: PropTypes.object.isRequired,
  minimised: PropTypes.bool
};

const ApodContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Apod);

export default withStyles(styles)(ApodContainer);
