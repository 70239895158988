import axios from 'axios';
import { parseList } from './api-utils';
import { API, API_KEY } from "./config";

// Picture of the day var url = "https://api.nasa.gov/planetary/apod?hd=true&api_key=wHLBJ0Yz1KUKokO3TcfYYqCgvVK6cLNo1xtNOf1Z";
//  var url = "https://api.nasa.gov/neo/rest/v1/feed?start_date=2015-09-07&end_date=2015-09-08&api_key=wHLBJ0Yz1KUKokO3TcfYYqCgvVK6cLNo1xtNOf1Z";

export const loadLatestAPODApi = async () => {
  console.log({API, API_KEY});
  const response = await axios.get(`${API}/planetary/apod?&api_key=${API_KEY}`);
  return parseList(response, 200);
};
