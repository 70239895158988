import React, { Component, lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { withRouter } from "react-router";
import { Header, NotFound } from "./components";
import { MuiThemeProvider, createMuiTheme, withStyles } from "@material-ui/core/styles";
import { yellow, amber } from "@material-ui/core/colors";
import "./styles.scss";
import "typeface-roboto";
import Apod from "./apod/Apod";
import connect from "react-redux/es/connect/connect";

const ImageSearch = withRouter(
  lazy(() => import(/* webpackChunkName: "heroes" */ "./search/image-search"))
);
const styles = {
  root: {
    flexGrow: 1,
    overflow: "hidden",
    width: "100%",
    opacity: 0.5,
    maxWidth: 500
  }
};

const theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: yellow,
    secondary: amber
  },
  typography: {
    useNextVariants: true,
    htmlFontSize: 10,
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      "\"Segoe UI\"",
      "Roboto",
      "\"Helvetica Neue\"",
      "Arial",
      "sans-serif",
      "\"Apple Color Emoji\"",
      "\"Segoe UI Emoji\"",
      "\"Segoe UI Symbol\""
    ].join(",")
  }
});

class App extends Component {
  state = {};

  render() {
    const { hasSearchResults } = this.props;
    return (
      <MuiThemeProvider theme={theme}>
        <div className="App">
          <Apod minimised={hasSearchResults}/>
          <Header title="NASA Explorer"
                  minimised={hasSearchResults}/>
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <Redirect from="/" exact to="/search"/>
              <Route path="/search" component={ImageSearch}/>
              <Route exact path="**" component={NotFound}/>
            </Switch>
          </Suspense>
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    hasSearchResults: state.search.resultsCount>0
  };
};

const AppContainer = connect(
  mapStateToProps
)(App);

export default withStyles(styles)(AppContainer);
